import { handleActions } from 'redux-actions';

import { updateGuestEvents } from './actions';

export default handleActions(
  {
    [updateGuestEvents]: (state, { payload: { data } }) => ({
      ...state,
      ...data,
    }),
  },
  {},
);
