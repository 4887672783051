import { updateSystemEmailTemplates, removeSystemEmailTemplate } from './actions';

export default {
  [updateSystemEmailTemplates]: (state, { payload }) => ({
    ...state,
    systemEmailTemplates: {
      ...state.systemEmailTemplates,
      ...payload,
    },
  }),
  [removeSystemEmailTemplate]: (state, { payload: { id } }) => ({
    ...state,
    systemEmailTemplates: {
      ...state.systemEmailTemplates,
      [id]: {
        ...state.systemEmailTemplates[id],
        archived: true,
      },
    },
  }),
};
