import React from 'react';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';

import styles from './styles.scss';

extendStyles(styles);

const Modal = ({ name, onConfirm, onCancel }) => (
  <div className={ styles.overlay }>
    <div className={ styles.modal }>
      <div className={ styles.inner }>
        <div className={ styles.close } onClick={ onCancel } />
        <div className={ styles.heading }>Everything looks good?</div>
        <div className={ styles.question } />
        <div className={ styles.separator } />
        <div className={ styles.text }>{ `Are you sure you want to submit the invoice for the session: ${name} ?` }</div>
        <div className={ styles.get('separator', 'green') } />
        <div className={ styles.buttonGroup }>
          <div className={ styles.get('button', 'yes') } onClick={ onConfirm }>Yes</div>
          <div className={ styles.get('button', 'no') } onClick={ onCancel }>No</div>
        </div>
      </div>
    </div>
  </div>
);

Modal.propTypes = {
  name: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default Modal;
