import React, { memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { sucessPageTemplateSelector } from 'store/selectors';

import styles from './styles.scss';

const Submitted = ({ pageTemplate }) => {
  console.log('pageTemplate', pageTemplate);
  return (
    <div className={ styles.contaner }>
      <div className={ styles.logo } />
      {pageTemplate && (
        <div className={ styles.text } dangerouslySetInnerHTML={ { __html: pageTemplate.content } } />
      )}
    </div>
  );
};

Submitted.propTypes = {
  pageTemplate: PropTypes.shape({
    content: PropTypes.string,
  }),
};

Submitted.defaultProps = {
  pageTemplate: null,
};

export default connect(sucessPageTemplateSelector)(memo(Submitted));
