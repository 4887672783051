import { createSelector } from 'reselect';
import { getProps } from 'helpers';

import { customProtocolsContainer } from '../base';

export const guestProtocolsSelector = createSelector(
  [customProtocolsContainer],
  ({ guestProtocols }) => ({ guestProtocols }),
);

export const guestProtocolByIdSelector = createSelector(
  [customProtocolsContainer, getProps],
  ({ guestProtocols }, { id }) => ({ guestProtocol: guestProtocols[id] }),
);

export const guestProtocolsBySessionIdSelector = createSelector(
  [customProtocolsContainer, getProps],
  ({ guestProtocols }, { sessionId }) => ({ sessionProtocols: Object
    .values(guestProtocols)
    .filter((gs) => gs.sessionId === sessionId) }),
);

export const guestProtocolsByGuestAndSessionSelector = createSelector(
  [customProtocolsContainer, getProps],
  ({ guestProtocols }, { guestId, sessionId }) => ({
    guestProtocols: Object
      .values(guestProtocols)
      .filter((gp) => gp.guestId === guestId && gp.sessionId === sessionId && !gp.archived),
  }),
);
