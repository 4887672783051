import { createActions } from 'redux-actions';

export const {
  editService,
  removeService,
  updateServices,
  editServiceAtomic,
  updateServiceTeams,
} = createActions(
  'EDIT_SERVICE',
  'REMOVE_SERVICE',
  'UPDATE_SERVICES',
  'EDIT_SERVICE_ATOMIC',
  'UPDATE_SERVICE_TEAMS',
);
