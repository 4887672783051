import { createActions } from 'redux-actions';

export const {
  editTips,
  removeTips,
  updateTips,
  editTipsAtomic,
} = createActions(
  'EDIT_TIPS',
  'REMOVE_TIPS',
  'UPDATE_TIPS',
  'EDIT_TIPS_ATOMIC',
);
