import { useState, useEffect, useCallback, useRef } from 'react';
import { w3cwebsocket as W3CWebSocket } from 'websocket';

import { logger } from 'helpers';
import { wsUrl, WS_CLIENT_ID } from 'settings';

import { fetchTeam } from './handlers/teams';
import { fetchTeamSession } from './handlers/team-sessions';

const useWebsocket = (params) => {
  const [isAlive, setIsAlive] = useState(false);
  const [waitingToReconnect, setWaitingToReconnect] = useState(null);

  const wsClient = useRef(null);

  const onReconnect = useCallback(() => {
    if (!wsClient.current) setWaitingToReconnect(null);
    else wsClient.close();
  }, []);

  useEffect(() => {
    const { hash } = params;
    const jsonStr = window.atob(hash);
    const { teamId } = JSON.parse(jsonStr);

    if (waitingToReconnect || !teamId) return;

    if (!wsClient.current) {
      const client = new W3CWebSocket(`${wsUrl}?id=${WS_CLIENT_ID}&userId=Invoice_T${teamId}`);
      wsClient.current = client;

      client.onopen = () => {
        logger.info('WebSocket Server Connected');
        setIsAlive(true);
      };

      client.onmessage = ({ data }) => {
        const jsonData = JSON.parse(data);

        if (jsonData.type === 'heartbeat') {
          client.send(JSON.stringify({ type: 'heartbeat' }));
        } else if (jsonData.wsMessageId !== undefined) {
          client.send(JSON.stringify({ type: 'confirm', messageId: jsonData.wsMessageId }));
        }

        switch (jsonData.action) {
          case 'fetchTeam':
            fetchTeam({ jsonData, updateError });
            break;
          case 'fetchTeamSession':
            fetchTeamSession({ jsonData, updateError });
            break;
          default:
            break;
        }
      };

      client.onclose = () => {
        if (waitingToReconnect || !wsClient.current) return;

        logger.error('WebSocket Server Disconnected');
        setIsAlive(false);
        setWaitingToReconnect(true);

        setTimeout(() => setWaitingToReconnect(null), 5000);
      };

      client.onerror = (err) => {
        logger.error('WebSocket encountered error: ', err, 'Closing socket');
        client.close();
      };

      return () => {
        logger.log('Cleanup');
        wsClient.current = null;
        client.close();
      };
    }
  }, [waitingToReconnect, params]);

  return { wsClient: wsClient.current, isAlive, onReconnect };
};

export default useWebsocket;
