import { updateInvoices } from 'store/actions';

import { api } from '..';
import { dispatch } from '../..';

const getTeamAppointments = ({ id, sessionId, startDate, endDate }) => api
  .get('/api/invoice/appointmentsByTherapistId', {
    params: {
      id,
      sessionId,
      start: startDate,
      end: endDate,
    },
  });

const getTeamInvoice = ({ id, sessionId }, cb) => {
  api.get('/api/invoice/getInvoice', {
    params: {
      id,
      sessionId,
    },
  }).then(({ data: { error, data } }) => {
    if (!error) {
      if (data) {
        const item = {
          id: data.id,
          teamId: data.teamId,
          sessionId: data.sessionId,
          expenses: data.expenses,
          notesTeam: data.notesTeam,
          payouts: data.payouts,
          status: data.status,
        };
        dispatch(updateInvoices({ data: { [data.id]: item } }));
        cb(item);
      } else {
        cb(null);
      }
    } else cb(null);
  });
};

const createInvoice = (formData, cb) => {
  api.post('/api/invoice/createInvoice', formData).then(({ data: { error, id } }) => {
    if (error) cb(false);
    else {
      const item = {
        [+id]: {
          id: +id,
          ...formData,
        },
      };
      dispatch(updateInvoices({ data: item }));
      cb(true);
    }
  });
};

const trackLink = (emailId) => {
  api.post('/api/email/trackLink', { emailId });
};

export default {
  trackLink,
  createInvoice,
  getTeamInvoice,
  getTeamAppointments,
};
