import React from 'react';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';

import { InputText } from './components';

import styles from './styles.scss';

extendStyles(styles);

const Notes = ({ locked, notes, handleChangeNote }) => {
  if (locked) return null;

  return (
    <div className={ styles.form }>
      <div className={ styles.title }>Notes regarding this invoice:</div>
      <div className={ styles.row }>
        <InputText
          name="notes"
          value={ notes }
          error={ false }
          style={ { width: '100%', margin: 0 } }
          onChange={ handleChangeNote }
          placeholder=""
        />
      </div>
    </div>
  );
};

Notes.propTypes = {
  notes: PropTypes.string,
  locked: PropTypes.bool.isRequired,
  handleChangeNote: PropTypes.func.isRequired,
};

Notes.defaultProps = {
  notes: null,
};

export default Notes;
