import { createSelector } from 'reselect';
import { getProps } from 'helpers';

import { itemsContainer } from '../items/base';

export const teamsContainer = ({ teams }) => teams;

export const teamsSelector = createSelector(
  [teamsContainer],
  ({ teams }) => ({ teams }),
);

export const teamByIdSelector = createSelector(
  [teamsContainer, getProps],
  ({ teams }, { id }) => ({ team: teams[id] }),
);

export const teamServicesSelector = createSelector(
  [teamsContainer],
  ({ teamServices }) => ({ teamServices }),
);

export const teamServicesByTeamIdSelector = createSelector(
  [itemsContainer, getProps],
  ({ services }, { teamId }) => ({ teamServices: Object
    .values(services)
    .reduce((res, service) => {
      const serviceHasTeam = service.teams.find((t) => t.id === teamId) !== undefined;
      const serviceExists = res.find((s) => s.id === service.id) !== undefined;
      return (serviceExists || !serviceHasTeam) ? res : [...res, service];
    }, []) }),
);

export const teamEventsByTeamIdSelector = createSelector(
  [itemsContainer, getProps],
  ({ events }, { teamId }) => ({ teamEvents: Object
    .values(events)
    .reduce((res, event) => {
      const eventHasTeam = event.teams.find((t) => t.id === teamId) !== undefined;
      const eventExists = res.find((s) => s.id === event.id) !== undefined;
      return (eventExists || !eventHasTeam) ? res : [...res, event];
    }, []) }),
);

export const teamJobsSelector = createSelector(
  [teamsContainer],
  ({ teamJobs }) => ({ teamJobs }),
);

export const teamJobsByTeamIdSelector = createSelector(
  [teamsContainer, getProps],
  ({ teamJobs }, { teamId }) => ({ teamJobs: Object
    .values(teamJobs)
    .filter((t) => t.teamId === teamId) }),
);

export const teamEmploymentsSelector = createSelector(
  [teamsContainer],
  ({ teamEmployments }) => ({ teamEmployments }),
);

export const teamEmploymentsByTeamIdSelector = createSelector(
  [teamsContainer, getProps],
  ({ teamEmployments }, { teamId }) => ({ teamEmployments: Object
    .values(teamEmployments)
    .filter((t) => t.teamId === teamId) }),
);

export const teamEmergencySelector = createSelector(
  [teamsContainer],
  ({ teamEmergencyContacts }) => ({ teamEmergencyContacts }),
);

export const teamEmergencyByTeamIdSelector = createSelector(
  [teamsContainer, getProps],
  ({ teamEmergencyContacts }, { teamId }) => ({ teamEmergency: Object
    .values(teamEmergencyContacts)
    .filter((t) => t.teamId === teamId) }),
);

export const teamNotesSelector = createSelector(
  [teamsContainer],
  ({ teamNotes }) => ({ teamNotes }),
);

export const teamNoteByTeamIdSelector = createSelector(
  [teamsContainer, getProps],
  ({ teamNotes }, { teamId }) => ({ teamNotes: Object
    .values(teamNotes)
    .filter((t) => t.teamId === teamId) }),
);
