import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';

import { InputGroup } from './components';

import styles from './styles.scss';

extendStyles(styles);

const AddItem = ({ onClose, onAddExpense }) => {
  const [formData, setFormData] = useState({
    item: null,
    subTotal: null,
    gst: null,
    pst: null,
    amount: null,
  });

  const handleChange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;

    if (fieldName === 'item') {
      setFormData((fd) => ({
        ...fd,
        [fieldName]: fieldValue,
      }));
    } else if (fieldName === 'subTotal') {
      const subTotal = fieldValue ? +fieldValue : 0;
      const gst = formData.gst ? +formData.gst  : 0;
      const pst = formData.pst ? +formData.pst  : 0;
      const totalAmount = subTotal + gst + pst;

      setFormData((fd) => ({
        ...fd,
        [fieldName]: fieldValue,
        amount: totalAmount.toFixed(2),
      }));
    } else if (fieldName === 'gst') {
      const subTotal = formData.subTotal ? +formData.subTotal : 0;
      const gst = fieldValue ? +fieldValue : 0;
      const pst = formData.pst ? +formData.pst  : 0;
      const totalAmount = subTotal + gst + pst;

      setFormData((fd) => ({
        ...fd,
        [fieldName]: fieldValue,
        amount: totalAmount.toFixed(2),
      }));
    } else if (fieldName === 'pst') {
      const subTotal = formData.subTotal ? +formData.subTotal : 0;
      const gst = formData.gst ? +formData.gst  : 0;
      const pst = fieldValue ? +fieldValue : 0;
      const totalAmount = subTotal + gst + pst;

      setFormData((fd) => ({
        ...fd,
        [fieldName]: fieldValue,
        amount: totalAmount.toFixed(2),
      }));
    }
  };

  const onSave = useCallback(() => {
    onAddExpense({
      item: formData.item,
      subTotal: formData.subTotal ? +formData.subTotal : null,
      gst: formData.gst ? +formData.gst : null,
      pst: formData.pst ? +formData.pst : null,
      amount: formData.amount ? +formData.amount : null,
      createdAt: new Date(),
    });
    onClose();
  }, [formData]);

  return (
    <div className={ styles.form }>
      <div className={ styles.row }>
        <InputGroup
          name="item"
          value={ formData.item }
          error={ false }
          style={ { width: '100%' } }
          onChange={ handleChange }
          placeholder="Expense"
        />
        <InputGroup
          name="subTotal"
          value={ formData.subTotal }
          error={ false }
          style={ { minWidth: 100 } }
          onChange={ handleChange }
          isDecimal
          isNumeric
          placeholder="Sub Total"
        />
        <InputGroup
          name="gst"
          value={ formData.gst }
          error={ false }
          style={ { minWidth: 100 } }
          onChange={ handleChange }
          isDecimal
          isNumeric
          placeholder="GST"
        />
        <InputGroup
          name="pst"
          value={ formData.pst }
          error={ false }
          style={ { minWidth: 100 } }
          onChange={ handleChange }
          isDecimal
          isNumeric
          placeholder="PST"
        />
        <InputGroup
          name="amount"
          value={ formData.amount }
          error={ false }
          style={ { minWidth: 100 } }
          onChange={ handleChange }
          isNumeric
          isDisabled
          placeholder="Amount"
        />
      </div>
      <div className={ styles.buttonGroup }>
        <div className={ styles.get('btn', 'save') } onClick={onSave}>Save</div>
        <div className={ styles.get('btn', 'cancel') } onClick={onClose}>Cancel</div>
      </div>
    </div>
  );
};

AddItem.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAddExpense: PropTypes.func.isRequired,
};

export default AddItem;
