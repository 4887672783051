import { handleActions } from 'redux-actions';

import { updateGuestServices } from './actions';

export default handleActions(
  {
    [updateGuestServices]: (state, { payload: { data } }) => ({
      ...state,
      ...data,
    }),
  },
  {},
);
