import teamHandler from 'store/effects/team/handlers';

export const fetchTeam = ({ jsonData, updateError }) => {
  teamHandler.getTeamById(jsonData.id, () => {
    let message = '';

    switch (jsonData.message) {
      case 'create-team':
        message = 'Team added';
        break;
      case 'edit-team':
        message = 'Team updated';
        break;
      case 'delete-team':
        message = 'Team removed';
        break;
      default:
        break;
    }

    updateError({
      open: true,
      message,
    });
  });
};
