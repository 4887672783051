import {
  editGuestProtocol,
  updateProtocolDays,
  removeGuestProtocol,
  updateSessionLabels,
  updateGuestProtocols,
  updateProtocolsFocus,
  updateProtocolInstructions,
} from './actions';

export default {
  [editGuestProtocol]: (state, { payload: { data } }) => {
    const { id, ...rest } = data;

    return {
      ...state,
      guestProtocols: {
        ...state.guestProtocols,
        [id]: {
          ...state.guestProtocols[id],
          ...rest,
        },
      },
    };
  },
  [removeGuestProtocol]: (state, { payload: { data: { id, deletedAt, deletedBy } } }) => ({
    ...state,
    guestProtocols: {
      ...state.guestProtocols,
      [id]: {
        ...state.guestProtocols[id],
        deletedAt,
        deletedBy,
        archived: true,
      },
    },
  }),
  [updateGuestProtocols]: (state, { payload: { data } }) => ({
    ...state,
    guestProtocols: {
      ...state.guestProtocols,
      ...data,
    },
  }),
  [updateProtocolDays]: (state, { payload: { id, days, protocolStarts } }) => ({
    ...state,
    guestProtocols: {
      ...state.guestProtocols,
      [id]: {
        ...state.guestProtocols[id],
        days,
        protocolStarts,
      },
    },
  }),
  [updateProtocolInstructions]: (state, { payload: { id, ...rest } }) => ({
    ...state,
    guestProtocols: {
      ...state.guestProtocols,
      [id]: {
        ...state.guestProtocols[id],
        ...rest,
      },
    },
  }),
  [updateProtocolsFocus]: (state, { payload: { protocolIds, mainHealthFocus } }) => {
    const updatedProtocols = protocolIds.reduce((res, id) => ({
      ...res,
      [id]: {
        ...state.guestProtocols[id],
        mainHealthFocus,
      },
    }), {});

    return {
      ...state,
      guestProtocols: {
        ...state.guestProtocols,
        ...updatedProtocols,
      },
    };
  },
  [updateSessionLabels]: (state, { payload: { id, sessionId, ...rest } }) => {
    const updatedProtocols = Object
      .values(state.guestProtocols)
      .filter((gp) => gp.sessionId === sessionId && gp.protocolId === id)
      .reduce((res, cur) => ({
        ...res,
        [cur.id]: {
          ...state.guestProtocols[cur.id],
          ...rest,
        },
      }), {});

    return {
      ...state,
      guestProtocols: {
        ...state.guestProtocols,
        ...updatedProtocols,
      },
    };
  },
};
