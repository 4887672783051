import { createActions } from 'redux-actions';

export const {
  editEvent,
  removeEvent,
  updateEvents,
  editEventAtomic,
  updateEventTeams,
} = createActions(
  'EDIT_EVENT',
  'REMOVE_EVENT',
  'UPDATE_EVENTS',
  'EDIT_EVENT_ATOMIC',
  'UPDATE_EVENT_TEAMS',
);
