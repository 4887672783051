import { combineReducers } from 'redux';

import auth from './auth/reducer';
import admin from './admin/reducer';
import teams from './teams/reducer';
import items from './items/reducer';
import guests from './guests/reducer';
import invoices from './invoices/reducer';
import sessions from './sessions/reducer';
import guestEvents from './guest-events/reducer';
import guestServices from './guest-services/reducer';
import customProtocols from './custom-protocols/reducer';

export default combineReducers({
  auth,
  admin,
  teams,
  items,
  guests,
  invoices,
  sessions,
  guestEvents,
  guestServices,
  customProtocols,
});
