import React from 'react';

import styles from './styles.scss';

const NotFound = () => (
  <div className={ styles.contaner }>
    <div className={ styles.heading }>It appears you dont have permissions to access this page.</div>
    <div className={ styles.subheading }>403 Error. Forbidden.</div>
  </div>
);

export default NotFound;
