import { createActions } from 'redux-actions';

export const {
  editProfessionalService,
  removeProfessionalService,
  updateProfessionalServices,
  editProfessionalServiceAtomic,
} = createActions(
  'EDIT_PROFESSIONAL_SERVICE',
  'REMOVE_PROFESSIONAL_SERVICE',
  'UPDATE_PROFESSIONAL_SERVICES',
  'EDIT_PROFESSIONAL_SERVICE_ATOMIC',
);
