import teamSessionHandler from 'store/effects/teamSession/handlers';

export const fetchTeamSession = ({ jsonData, updateError }) => {
  teamSessionHandler.handleGetTeamSessionById(jsonData.id, () => {
    let message = '';

    switch (jsonData.message) {
      case 'create-team-session':
        message = 'Team Session added.';
        break;
      case 'edit-team-session':
        message = 'Team Session updated.';
        break;
      case 'delete-team-session':
        message = 'Team Session removed.';
        break;
      default:
        break;
    }

    updateError({
      open: true,
      message,
    });
  });
};