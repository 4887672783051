import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { getOptionTextById } from 'helpers';

import styles from './styles.scss';

const Header = ({ team, invoice, sessions, currentSession, options }) => {
  const address = useMemo(() => {
    const country = getOptionTextById({ data: options, field: 'countries', id: team.country });
    return `${team.city}, ${team.provinceState}, ${team.postalCode}, ${country}`;
  }, [team, options]);

  const phone = useMemo(() => {
    if (team.phoneRegInvoice === 'cell') return team.phoneCell;
    if (team.phoneRegInvoice === 'work') return team.phoneWork;
    if (team.phoneRegInvoice === 'home') return team.phoneHome;
    if (team.phonePreffered === 'cell') return team.phoneCell;
    if (team.phonePreffered === 'work') return team.phoneWork;
    if (team.phonePreffered === 'home') return team.phoneHome;
    return null;
  }, [team]);

  const paymentMethod = useMemo(() => {
    if (!invoice.payouts || !invoice.payouts.length) return null;
    const lastPayout = invoice.payouts[invoice.payouts.length - 1];
    return getOptionTextById({ data: options, field: 'paymentMethods', id: lastPayout.paymentMethod });
  }, [invoice.payouts, options]);

  const paidOn = useMemo(() => {
    if (!invoice.payouts || !invoice.payouts.length) return null;
    return invoice.payouts.reduce((res, cur) => {
      if (!res) return moment(cur.paidOn);
      return moment(cur.paidOn).isAfter(res) ? moment(cur.paidOn) : res;
    }, null);
  }, [invoice.payouts]);

  return (
    <div className={ styles.row }>
      <div className={ styles.column }>
        {team.businessLogoUrl && (
          <img className={ styles.logo } src={ team.businessLogoUrl } alt={ `${team.firstName} ${team.lastName}` } />
        )}
        {team.companyName && (
          <div className={ styles.label } style={ { fontFamily: 'LatoBold' } }>{ team.companyName }</div>
        )}
        <div className={ styles.label } style={ { fontFamily: 'LatoBold' } }>{ `${team.firstName} ${team.lastName}` }</div>
        <div className={ styles.label }>{ team.streetAddress1 }</div>
        <div className={ styles.label }>{ address }</div>
        <div className={ styles.label }>
          { phone && `${phone}${team.emailPrimary ? ' | ' : ''}` }
          {team.emailPrimary && (
            <a href={ `mailto:${team.emailPrimary}` } target="_blank" rel="noopener noreferrer">{ team.emailPrimary }</a>
          )}
        </div>
        <div className={ styles.label }>Tax ID # {team.businessNumber}</div>
        <div className={ styles.label } style={ { marginTop: 10 } }>
          <strong>Payment terms: </strong>
          <span>within 7-10 days after program is over</span>
        </div>
      </div>
      <div className={ styles.column }>
        <div className={ styles.label } style={ { fontSize: 18, marginBottom: 10 } }>Invoice</div>
        <div className={ styles.label } style={ { marginBottom: 10 } }>{ `Date: ${moment().format('YYYY-MM-DD')}` }</div>
        {sessions && currentSession && sessions[currentSession] && (
          <div className={ styles.label } style={ { marginBottom: 10 } }>{ `Session: ${sessions[currentSession].name}` }</div>
        )}
        <div className={ styles.label } style={ { fontFamily: 'LatoBold' } }>Fresh Start Health Retreat Centers Ltd.</div>
        <div className={ styles.label }>4384 South Island Highway</div>
        <div className={ styles.label }>Campbell River, BC, V9H 1E8, Canada</div>
        <div className={ styles.label }>
          { '1 (888) 658-3324 | ' }
          <a href="https://www.healthretreat.ca/" target="_blank" rel="noopener noreferrer">www.healthretreat.ca</a>
        </div>
        <div className={ styles.label }>Tax ID # 82132 3656</div>
        <div className={ styles.label } style={ { marginTop: 10 } }>
          <strong>Paid on: </strong>
          {invoice.payouts && invoice.payouts.length
            ? <span>{`${moment(paidOn).format('MMM DD, YYYY')} | ${paymentMethod}`}</span>
            : <span>Unpaid</span>}
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  team: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    country: PropTypes.number,
    provinceState: PropTypes.string,
    streetAddress1: PropTypes.string,
    companyName: PropTypes.string,
    phoneCell: PropTypes.string,
    phoneWork: PropTypes.string,
    phoneHome: PropTypes.string,
    phonePreffered: PropTypes.string,
    phoneRegInvoice: PropTypes.string,
    emailPrimary: PropTypes.string,
    businessNumber: PropTypes.string,
    businessLogoUrl: PropTypes.string,
  }).isRequired,
  invoice: PropTypes.shape({
    payouts: PropTypes.arrayOf(PropTypes.shape({
      paymentMethod: PropTypes.number,
    })),
  }),
  options: PropTypes.shape({}),
  sessions: PropTypes.shape({}),
  currentSession: PropTypes.number,
};

Header.defaultProps = {
  invoice: null,
  options: null,
  sessions: null,
  currentSession: null,
};

export default memo(Header);
