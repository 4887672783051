import { createRoot } from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';

import store from './store';
import { App } from './components';

import './styles/global.scss';

const history = createBrowserHistory();

const el = document.querySelector('#root');
const root = createRoot(el);

root.render(
  <Provider store={ store }>
    <HashRouter history={ history }>
      <App />
    </HashRouter>
  </Provider>,
);
