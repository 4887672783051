import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';

import Snackbar from '@material-ui/core/Snackbar';
import invoiceHandler from 'store/effects/invoice/handlers';
import { useWebsocket } from 'hooks';
import { getTaxAmountById } from 'helpers';

import { useData } from './hooks';
import { updateData } from './helpers';
import { Header, List, Expenses, GrandTotal, Notes, Modal, Message } from './components';

import styles from './styles.scss';

const Invoice = () => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  useWebsocket(params);
  const { teams, sessions, teamSessions, options, submitInvoiceTemplate } = useData();

  const [team, setTeam] = useState(null);
  const [modal, setModal] = useState(false);
  const [invoice, setInvoice] = useState({
    status: 'locked',
    expenses: [],
    notesTeam: null,
  });
  const [action, setAction] = useState({ error: false, done: false });
  const [appointments, setAppointments] = useState(null);
  const [teamSessionId, setTeamSessionId] = useState(null);
  const [currentSession, setCurrentSession] = useState(null);
  const [invoiceSummary, setInvoiceSummary] = useState({});

  useEffect(() => {
    if (!teams) return;

    try {
      const { hash } = params;
      const jsonStr = atob(hash);
      const { teamId = null, sessionId = null } = JSON.parse(jsonStr);
      if (!teamId || !sessionId) {
        history.push('/');
        return;
      }
      setTeam(teams[teamId]);
      setCurrentSession(sessionId);
    } catch (e) {
      history.push('/');
    }
  }, [params, history, teams]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const emailId = query.get('trackLink');

    if (emailId) {
      invoiceHandler.trackLink(emailId);
    }
  }, [location.search]);

  useEffect(() => {
    if (!currentSession || !team || !sessions || !teamSessions || !sessions[currentSession]) return;

    const teamSession = Object.values(teamSessions || {})
      .find((ts) => ts.teamId === team.id && ts.sessionId === currentSession && !ts.archived);

    if (teamSession) {
      setTeamSessionId(teamSession.id);

      invoiceHandler
        .getTeamAppointments({
          id: teamSession.id,
          sessionId: currentSession,
          startDate: moment(sessions[currentSession].startDate).format('YYYY-MM-DD'),
          endDate: moment(sessions[currentSession].endDate).format('YYYY-MM-DD'),
        })
        .then(({ data: { data = [] } }) => {
          const services = data.reduce((res, cur) => ({
            ...res,
            [cur.name]: {
              name: cur.name,
              qty: ((res[cur.name] || {}).qty || 0) + 1,
              rate: cur.price,
            },
          }), {});
          setAppointments(services);
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log('Team appointments error', e);
        });

      invoiceHandler.getTeamInvoice({
        id: teamSession.id,
        sessionId: currentSession,
      }, (data) => {
        if (data) setInvoice(data);
        else {
          setInvoice({
            paidOn: null,
            status: 'unlocked',
            expenses: [],
            notesTeam: null,
            paymentMethod: null,
          });
        }
      });
    } else {
      setInvoice({
        paidOn: null,
        status: 'locked',
        expenses: [],
        notesTeam: null,
        paymentMethod: null,
      });
      setAppointments(null);
    }
  }, [currentSession, team, sessions, teamSessions]);

  useEffect(() => {
    if (!team) return;

    const teamTax = getTaxAmountById({ data: options, field: 'taxes', id: team.purchaseTax });
    const servicesSubTotal = Object.values(appointments || {}).reduce((res, cur) => res + cur.qty * cur.rate, 0);
    const serviceGst = (servicesSubTotal && teamTax) ? (servicesSubTotal * teamTax) / 100 : 0;

    const expensesSubTotal = (invoice.expenses || []).reduce((res, cur) => res + cur.subTotal, 0);
    const expensesGst = (invoice.expenses || []).reduce((res, cur) => res + (cur.gst || 0), 0);
    const expensesPst = (invoice.expenses || []).reduce((res, cur) => res + (cur.pst || 0), 0);

    setInvoiceSummary({
      servicesSubTotal,
      expensesSubTotal,
      gst: serviceGst + expensesGst,
      pst: expensesPst,
      grandTotal: servicesSubTotal + expensesSubTotal + serviceGst + expensesGst + expensesPst,
    });
  }, [team, appointments, invoice, options]);

  const onAddExpense = useCallback((expense) => {
    setInvoice((state) => ({
      ...state,
      expenses: [...state.expenses, expense],
    }));
  }, []);

  const onEditExpense = useCallback((expense, index) => {
    setInvoice((state) => ({
      ...state,
      expenses: state.expenses.map((item, i) => (i === index ? expense : item)),
    }));
  }, []);

  const onDeleteExpense = useCallback((index) => {
    setInvoice((state) => ({
      ...state,
      expenses: state.expenses.filter((item, i) => index !== i),
    }));
  }, []);

  const handleChangeNote = useCallback((e) => {
    const fieldValue = e.target.value;

    setInvoice((state) => ({
      ...state,
      notesTeam: fieldValue,
    }));
  }, []);

  const prepareEmail = () => {
    const data = {
      team,
      invoice,
      options,
      sessions,
      teamSessions,
      teamSessionId,
      currentSession,
      invoiceSummary,
    };

    const mergedSubject = updateData({
      data,
      template: submitInvoiceTemplate.subject,
    });

    const mergedEmailBody = updateData({
      data,
      template: submitInvoiceTemplate.emailBody,
    });

    return {
      subject: mergedSubject,
      toEmail: submitInvoiceTemplate.toEmail,
      fromEmail: team.emailPrimary || team.emailSecondary,
      emailBody: mergedEmailBody,
    };
  };

  const toggleModalShow = () => setModal(true);
  const toggleModalClose = () => setModal(false);
  const toggleModalConfirm = () => {
    setModal(false);
    invoiceHandler.createInvoice({
      teamId: teamSessionId,
      sessionId: currentSession,
      expenses: invoice.expenses,
      notesTeam: invoice.notesTeam,
      createdAt: new Date(),
      emailData: prepareEmail(),
    }, (done) => {
      if (!done) {
        setAction({ error: !done, done });
        return;
      }
      history.push('/success');
    });
  };

  const handleCloseMessage = () => setAction({ error: false, done: false });

  if (!team || !teamSessionId || !currentSession) return null;

  return (
    <div className={ styles.container }>
      <Header
        team={ team }
        invoice={ invoice }
        options={ options }
        sessions={ sessions }
        currentSession={ currentSession }
      />
      <List
        team={ team }
        appointments={ appointments }
      />
      <Expenses
        locked={ invoice.status === 'locked' }
        expenses={ invoice.expenses }
        onAddExpense={ onAddExpense }
        onEditExpense={ onEditExpense }
        onDeleteExpense={ onDeleteExpense }
      />
      <GrandTotal
        team={ team }
        expenses={ invoice.expenses }
        appointments={ appointments }
      />
      <Notes
        notes={ invoice.notesTeam }
        locked={ invoice.status === 'locked' }
        handleChangeNote={ handleChangeNote }
      />
      {invoice.status !== 'locked' && (
        <div className={ styles.button } onClick={ toggleModalShow }>Submit</div>
      )}
      {modal && (
        <Modal
          name={ sessions[currentSession].name }
          onConfirm={ toggleModalConfirm }
          onCancel={ toggleModalClose }
        />
      )}
      <Snackbar
        anchorOrigin={ { vertical: 'bottom', horizontal: 'left' } }
        open={ action.error }
        autoHideDuration={ 5000 }
        onClose={ handleCloseMessage }
      >
        <Message message="Error" variant="error" onClose={ handleCloseMessage } />
      </Snackbar>
      <Snackbar
        anchorOrigin={ { vertical: 'bottom', horizontal: 'left' } }
        open={ action.done }
        autoHideDuration={ 5000 }
        onClose={ handleCloseMessage }
      >
        <Message message="Invoice created" variant="success" onClose={ handleCloseMessage } />
      </Snackbar>
    </div>
  );
};

export default Invoice;
