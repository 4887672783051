import { updatePriceFormulas } from './actions';

export default {
  [updatePriceFormulas]: (state, { payload }) => ({
    ...state,
    priceFormulas: {
      ...state.priceFormulas,
      ...payload,
    },
  }),
};
