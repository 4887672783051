import { v4 as uuidv4 } from 'uuid';

export const IS_PROD = process.env.TARGET_ENV === 'production';
const PORT = IS_PROD ? 3000 : 3001;

export const wsUrl = `wss://cspapi.fsportal.site:${PORT}`;
export const apiUrl = `https://cspapi.fsportal.site:${PORT}`;
export const appUrl = `https://${IS_PROD ? '' : 'staging.'}csportal.fsportal.site`;
export const auditUrl = `https://${IS_PROD ? '' : 'staging.'}audit.fsportal.site`;
export const invoiceUrl = `https://${IS_PROD ? '' : 'staging.'}team.fsportal.site`;
export const infusionApiUrl = 'https://csportal.fsportal.site/infusion-api/api.php';

export const WS_CLIENT_ID = uuidv4();