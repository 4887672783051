import { createActions } from 'redux-actions';

export const {
  editServiceCredit,
  removeServiceCredit,
  updateServiceCredits,
  editServiceCreditAtomic,
} = createActions(
  'EDIT_SERVICE_CREDIT',
  'REMOVE_SERVICE_CREDIT',
  'UPDATE_SERVICE_CREDITS',
  'EDIT_SERVICE_CREDIT_ATOMIC',
);
