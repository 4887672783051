import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import {
  teamsSelector,
  optionsSelector,
  sessionsSelector,
  teamSessionsSelector,
  submitInvoiceEmailTemplateSelector,
} from 'store/selectors';

const getData = createSelector(
  teamsSelector,
  optionsSelector,
  sessionsSelector,
  teamSessionsSelector,
  submitInvoiceEmailTemplateSelector,
  (
    { teams },
    { options },
    { sessions },
    { teamSessions },
    { submitInvoiceTemplate }) => ({
    teams,
    options,
    sessions,
    teamSessions,
    submitInvoiceTemplate,
  }));

const useDataFromStore = () => useSelector(getData);

export default useDataFromStore;
