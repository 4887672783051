import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { extendStyles, formatCurrency, getTaxAmountById } from 'helpers';

import { useData } from '../../hooks';

import styles from './styles.scss';

extendStyles(styles);

const List = ({ team, appointments }) => {
  const { options } = useData();

  const subTotal = useMemo(() => Object.values(appointments || {}).reduce((res, cur) => res + cur.qty * cur.rate, 0),
    [appointments]);

  const gst = useMemo(() => {
    const tax = getTaxAmountById({ data: options, field: 'taxes', id: team.purchaseTax });
    return (subTotal && tax) ? (subTotal * tax) / 100 : 0;
  }, [subTotal, team, options]);

  const total = useMemo(() => (subTotal || 0) + gst, [subTotal, gst]);

  return (
    <div className={ styles.wrapper }>
      <div className={ styles.title }>Therapies & Classes</div>
      <div className={ styles.header }>
        <div className={ styles.get('cell', 'item') }>Item</div>
        <div className={ styles.get('cell', 'qty') }>Qty</div>
        <div className={ styles.get('cell', 'rate') }>Rate</div>
        <div className={ styles.get('cell', 'amount') }>Amount</div>
      </div>
      {Object.values(appointments || {}).map((apt) => (
        <div key={ apt.name } className={ styles.row }>
          <div className={ styles.get('cell', 'item') }>{apt.name}</div>
          <div className={ styles.get('cell', 'qty') }>{apt.qty}</div>
          <div className={ styles.get('cell', 'rate') }>{apt.rate}</div>
          <div className={ styles.get('cell', 'amount') }>{formatCurrency(apt.qty * apt.rate)}</div>
        </div>
      ))}
      <div className={ styles.get('total', 'offset') }>
        <div className={ styles.label }>Sub Total:</div>
        <div className={ styles.amount }>{formatCurrency(subTotal)}</div>
      </div>
      <div className={ styles.total }>
        <div className={ styles.label }>GST:</div>
        <div className={ styles.amount }>{formatCurrency(gst)}</div>
      </div>
      <div className={ styles.total }>
        <div className={ styles.label }>Total:</div>
        <div className={ styles.amount }>{formatCurrency(total)}</div>
      </div>
    </div>
  );
};

List.propTypes = {
  team: PropTypes.shape({
    purchaseTax: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  appointments: PropTypes.shape({
    name: PropTypes.string,
    qty: PropTypes.number,
    rate: PropTypes.number,
  }),
};

List.defaultProps = {
  appointments: null,
};

export default List;
