import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';

import teamHandler from 'store/effects/team/handlers';
import adminHandler from 'store/effects/admin/handlers';
import sessionHandler from 'store/effects/session/handlers';
import teamSessionHandler from 'store/effects/teamSession/handlers';

import { Invoice, NotFound, Submitted } from '..';

const App = () => {
  useEffect(() => {
    teamHandler.getAllTeams();
    adminHandler.getAllAdminData();
    sessionHandler.handleGetAllSessions();
    teamSessionHandler.handleGetAllTeamSessions();
  }, []);

  return (
    <Switch>
      <Route path="/success" component={ Submitted } />
      <Route path="/:hash" component={ Invoice } />
      <Route path="*" component={ NotFound } />
    </Switch>
  );
};

export default App;
