/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';

import { extendStyles } from 'helpers';

import styles from './styles.scss';

extendStyles(styles);

const InputGroup = (props) => {
  const {
    name,
    type,
    label,
    value,
    error,
    style,
    onChange,
    autoFocus,
    isDecimal,
    isNumeric,
    isNegative,
    isDisabled,
    placeholder,
  } = props;
  const handleEdit = (e) => {
    e.persist();

    const str = e.target.value;

    if (!isNumeric) {
      onChange(e);
    } else {
      let regExp;
      if (isDecimal) {
        regExp = isNegative
          ? new RegExp(/^(-)?([0-9]+)?\.?([0-9]+)?$/)
          : new RegExp(/^([0-9]+)?\.?([0-9]+)?$/);
      } else regExp = isNegative ? new RegExp(/^(-)?([0-9]+)?$/) : new RegExp(/^([0-9]+)?$/);

      if (!regExp.test(str)) {
        e.returnValue = false;
        if (e.preventDefault) e.preventDefault();
      } else {
        onChange(e);
      }
    }
  };

  const inputStyle = style.backgroundColor ? {
    backgroundColor: style.backgroundColor,
  } : {};
  const { backgroundColor, ...restStyles } = style;

  return (
    <div className={ styles.get('input-group') } style={ restStyles }>
      { label && <div className={ styles.get('label') }>{ label }</div> }
      <input
        type={ type }
        name={ name }
        value={ value === null ? '' : value }
        style={ inputStyle }
        onChange={ handleEdit }
        className={ styles.get('input', error && 'error') }
        placeholder={ placeholder }
        disabled={ isDisabled }
        autoFocus={ autoFocus }
        autoComplete="off"
      />
    </div>
  );
};

InputGroup.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  error: PropTypes.bool,
  style: PropTypes.shape({
    backgroundColor: PropTypes.string,
  }),
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  isDecimal: PropTypes.bool,
  isNumeric: PropTypes.bool,
  isNegative: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

InputGroup.defaultProps = {
  type: 'text',
  label: null,
  value: null,
  error: false,
  style: {},
  onChange: () => {},
  autoFocus: false,
  isDecimal: true,
  isNumeric: false,
  isNegative: false,
  isDisabled: false,
  placeholder: null,
};

export default InputGroup;
