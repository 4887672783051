import { createActions } from 'redux-actions';

export const {
  editInvoice,
  removeInvoice,
  updateInvoices,
} = createActions(
  'EDIT_INVOICE',
  'REMOVE_INVOICE',
  'UPDATE_INVOICES',
);
