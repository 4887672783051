import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { extendStyles, formatCurrency } from 'helpers';

import { AddItem, EditItem } from './components';

import styles from './styles.scss';

extendStyles(styles);

const Expenses = ({ locked, expenses, onAddExpense, onEditExpense, onDeleteExpense }) => {
  const [edit, setEdit] = useState(null);
  const [isAdd, setIsAdd] = useState(false);

  const subTotal = useMemo(() => {
    return Object.values(expenses || {}).reduce((res, cur) => res + cur.subTotal, 0);
  }, [expenses]);
  const gst = useMemo(() => {
    return Object.values(expenses || {}).reduce((res, cur) => res + (cur.gst || 0), 0);
  }, [expenses]);
  const pst = useMemo(() => {
    return Object.values(expenses || {}).reduce((res, cur) => res + (cur.pst || 0), 0);
  }, [expenses]);
  const total = useMemo(() => {
    return Object.values(expenses || {}).reduce((res, cur) => res + cur.amount, 0);
  }, [expenses]);

  const onToggleAdd = useCallback(() => {
    setIsAdd((s) => !s);
  }, []);
  const onToggleEdit = useCallback((index) => {
    setEdit(index);
  }, []);

  const onClose = useCallback(() => {
    setIsAdd(false);
    setEdit(null);
  }, []);

  const handleEditExpense = (expense, index) => {
    setEdit(null);
    onEditExpense(expense, index);
  };

  const renderExpense = (expense, index) => (edit === index
    ? (
      <EditItem
        key={expense.item}
        index={ index }
        expense={ expense }
        onClose={ onClose }
        onEditExpense={ handleEditExpense }
      />
    )
    : (
      <div key={expense.item} className={ styles.row }>
        <div className={ styles.get('cell', 'item') }>{expense.item}</div>
        <div className={ styles.get('cell', 'subTotal') }>{formatCurrency(expense.subTotal)}</div>
        <div className={ styles.get('cell', 'gst') }>{formatCurrency(expense.gst, '$0')}</div>
        <div className={ styles.get('cell', 'pst') }>{formatCurrency(expense.pst, '$0')}</div>
        <div className={ styles.get('cell', 'amount') }>{formatCurrency(expense.amount)}</div>
        {!isAdd && !edit && !locked && (
          <div className={ styles.icons }>
            <div className={ styles.edit } onClick={() => onToggleEdit(index)} />
            <div className={ styles.delete } onClick={() => onDeleteExpense(index)} />
          </div>
        )}
      </div>
    ));

  return (
    <div className={ styles.container }>
      {!isAdd && !locked && (
        <div className={ styles.button } onClick={onToggleAdd}>Add Expenses</div>
      )}
      <div className={ styles.title }>
        <strong>Expenses</strong>
        <span>*Only approved and agreed-upon with Fresh Start expenses will be accepted</span>
      </div>
      <div className={ styles.header }>
        <div className={ styles.get('cell', 'item') }>Item</div>
        <div className={ styles.get('cell', 'subTotal') }>Sub Total</div>
        <div className={ styles.get('cell', 'gst') }>GST</div>
        <div className={ styles.get('cell', 'pst') }>PST</div>
        <div className={ styles.get('cell', 'amount') }>Amount</div>
      </div>
      {(expenses || []).map(renderExpense)}
      {isAdd && <AddItem onClose={onClose} onAddExpense={onAddExpense}/>}
      <div className={ styles.get('total', 'offset') }>
        <div className={ styles.label }>Sub Total:</div>
        <div className={ styles.amount }>{formatCurrency(subTotal)}</div>
      </div>
      {gst > 0 && (
        <div className={ styles.total }>
          <div className={ styles.label }>GST:</div>
          <div className={ styles.amount }>{formatCurrency(gst)}</div>
        </div>
      )}
      {pst > 0 && (
        <div className={ styles.total }>
          <div className={ styles.label }>PST:</div>
          <div className={ styles.amount }>{formatCurrency(pst)}</div>
        </div>
      )}
      <div className={ styles.total }>
        <div className={ styles.label }>Total:</div>
        <div className={ styles.amount }>{formatCurrency(total)}</div>
      </div>
      <div className={ styles.separator } />
    </div>
  );
};

Expenses.propTypes = {
  locked: PropTypes.bool.isRequired,
  expenses: PropTypes.arrayOf(PropTypes.shape({})),
  onAddExpense: PropTypes.func.isRequired,
  onEditExpense: PropTypes.func.isRequired,
  onDeleteExpense: PropTypes.func.isRequired,
};

Expenses.defaultProps = {
  expenses: null,
};

export default Expenses;
