import { handleActions } from 'redux-actions';

import tips from './tips/reducer';
import events from './events/reducer';
import general from './general/reducer';
import services from './services/reducer';
import serviceCredits from './serviceCredits/reducer';
import professionalServices from './professionalServices/reducer';

export default handleActions(
  {
    ...tips,
    ...events,
    ...general,
    ...services,
    ...serviceCredits,
    ...professionalServices,
  },
  {
    tips: {},
    events: {},
    general: {},
    services: {},
    serviceCredits: {},
    professionalServices: {},
  },
);
