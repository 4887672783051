import {
  editProfessionalService,
  removeProfessionalService,
  updateProfessionalServices,
  editProfessionalServiceAtomic,
} from './actions';

export default {
  [editProfessionalService]: (state, { payload: { data } }) => {
    const { id, ...rest } = data;

    return {
      ...state,
      professionalServices: {
        ...state.professionalServices,
        [id]: {
          ...state.professionalServices[id],
          ...rest,
        },
      },
    };
  },
  [removeProfessionalService]: (state, { payload: { data: { id, deletedAt, deletedBy } } }) => ({
    ...state,
    professionalServices: {
      ...state.professionalServices,
      [id]: {
        ...state.professionalServices[id],
        deletedAt,
        deletedBy,
        archived: true,
      },
    },
  }),
  [updateProfessionalServices]: (state, { payload: { data } }) => ({
    ...state,
    professionalServices: {
      ...state.professionalServices,
      ...data,
    },
  }),
  [editProfessionalServiceAtomic]: (state, { payload: { id, field, value, updatedAt, updatedBy } }) => ({
    ...state,
    professionalServices: {
      ...state.professionalServices,
      [id]: {
        ...state.professionalServices[id],
        [field]: value,
        updatedAt,
        updatedBy,
      },
    },
  }),
};
