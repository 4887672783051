import { updatePageTemplates } from './actions';

export default {
  [updatePageTemplates]: (state, { payload }) => ({
    ...state,
    pageTemplates: {
      ...state.pageTemplates,
      ...payload,
    },
  }),
};
