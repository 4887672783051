import { apiUrl, invoiceUrl } from 'settings';
import { formatCurrency, getOptionTextById } from 'helpers';

const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

const replaceAll = (str, find, replace) => str.replace(new RegExp(escapeRegExp(find), 'g'), replace);

export const updateData = ({ data, template }) => {
  let result = template;

  if (result.indexOf('[Therapist_FirstName]') !== -1 && data.team !== undefined) {
    const { firstName } = data.team;

    result = replaceAll(result, '[Therapist_FirstName]', firstName);
  }

  if (result.indexOf('[Therapist_LastName]') !== -1 && data.team !== undefined) {
    const { lastName } = data.team;

    result = replaceAll(result, '[Therapist_LastName]', lastName);
  }

  if (result.indexOf('[Therapist_Invoice_Summary]') !== -1 && data.invoiceSummary !== undefined) {
    const htmlTable = `
    <table style="width: 500px; margin-bottom: 20px; border-collapse: collapse;" cellspacing="0">
      <tr style="background: #ebfbd2;">
        <td style="width: 100px; border: 1px solid #9ecc4e;">Service</td>
        <td style="width: 100px; border: 1px solid #9ecc4e;">Expenses</td>
        <td style="width: 100px; border: 1px solid #9ecc4e;">GST</td>
        <td style="width: 100px; border: 1px solid #9ecc4e;">PST</td>
        <td style="width: 100px; border: 1px solid #9ecc4e;">Total</td>
      <tr>
      <tr style="background: #fff;">
        <td style="width: 100px; border: 1px solid #9ecc4e;">${formatCurrency(data.invoiceSummary.servicesSubTotal)}</td>
        <td style="width: 100px; border: 1px solid #9ecc4e;">${formatCurrency(data.invoiceSummary.expensesSubTotal)}</td>
        <td style="width: 100px; border: 1px solid #9ecc4e;">${formatCurrency(data.invoiceSummary.gst)}</td>
        <td style="width: 100px; border: 1px solid #9ecc4e;">${formatCurrency(data.invoiceSummary.pst)}</td>
        <td style="width: 100px; border: 1px solid #9ecc4e;">${formatCurrency(data.invoiceSummary.grandTotal)}</td>
      </tr>
    </table>
    `;

    result = replaceAll(result, '[Therapist_Invoice_Summary]', htmlTable);
  }

  if (result.indexOf('[Therapist_Invoice_Notes]') !== -1 && data.invoice !== undefined) {
    result = replaceAll(result, '[Therapist_Invoice_Notes]', data.invoice.notesTeam || '');
  }

  if (result.indexOf('[Link_SalesByTherapist]') !== -1 && data.teamSessionId !== undefined && data.sessions !== undefined) {
    const { startDate, endDate } = data.sessions[data.currentSession];
    const link = `${apiUrl}/api/report/appointmentsByTherapistIds?ids=[${data.teamSessionId}]&sessionId=${data.currentSession}&start=${startDate}&end=${endDate}&showSales=true`;
    const hyperlink = `<a href="${link}" target="_blank">Appointments Link</a>`;
    result = replaceAll(result, '[Link_SalesByTherapist]', hyperlink);
  }

  if (result.indexOf('[Link_InvoiceTemplate]') !== -1 && data.currentSession && data.team !== undefined) {
    const hash = window.btoa(JSON.stringify({ teamId: data.team.id, sessionId: data.currentSession }));

    const link = `${invoiceUrl}/#/${hash}`;
    const hyperlink = `<a href="${link}" target="_blank">Invoice Template</a>`;
    result = replaceAll(result, '[Link_InvoiceTemplate]', hyperlink);
  }

  if (result.indexOf('[Sender_Signature]') !== -1 && data.team !== undefined && data.options !== undefined) {
    const teamName = `${data.team.firstName} ${data.team.lastName}`;
    const teamAddress = `${data.team.streetAddress1 || ''} ${data.team.streetAddress2 || ''}`;
    const teamCity = data.team.city || '';
    const teamState = data.team.provinceState || '';
    const teamZip = data.team.postalCode || '';
    const teamCountry = getOptionTextById({ data: data.options, field: 'countries', id: +data.team.country }) || '';
    const teamPhone = data.team.phoneCell || data.team.phoneWork || data.team.phoneHome || '';
    const teamEmail = data.team.emailPrimary || data.team.emailSecondary;

    let htmlSignature = [
      `<b>${teamName}</b>`,
      teamAddress,
      `${teamCity}, ${teamState}, ${teamZip}, ${teamCountry}`,
    ]
      .filter((line) => line.trim().length > 0)
      .map((line) => `<p>${line}</p>`)
      .join('\n');

    if (teamPhone && teamEmail) htmlSignature += `<p>${teamPhone} | <a href="mailto:${teamEmail}">${teamEmail}</a></p>`;
    else if (teamPhone && !teamEmail) htmlSignature += `<p>${teamPhone}</p>`;
    else if (!teamPhone && teamEmail) htmlSignature += `<p><a href="mailto:${teamEmail}">${teamEmail}</a></p>`;

    result = replaceAll(result, '[Sender_Signature]', htmlSignature);
  }

  return result;
};
